import { notification } from 'antd';
import axios from 'axios';
import { invalidTokenDetail } from 'config/auth';
import { localStorageKey } from 'config/localStorage';
import history from 'services/history';
import localStorage from 'services/localStorage';
import logger from 'services/logger';
import { URL_LOGIN } from './Paths';
import CODES from './StatusCodes';
import { t } from 'translation/i18n';

const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
  DOWNLOAD: 'download',
};

const BASEURL = process.env.REACT_APP_API_URL;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  constructor() {
    this.baseURL = BASEURL;
  }

  get accessToken() {
    return localStorage.getItem(localStorageKey.accessToken);
  }

  get isLoggedIn() {
    return !!this.accessToken;
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(logger.error);
    });
  }

  download(url, data, fileName = 'file.blob') {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DOWNLOAD, url, data)
        .then((response) => {
          if (response.status === CODES.SUCCESS) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
          resolve(response);
        })
        .catch(logger.error);
    });
  }

  post(url, data, baseUrl) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data, baseUrl)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          logger.error(error);
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(logger.error);
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(logger.error);
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(logger.error);
    });
  }

  api(method, url, data, baseUrl) {
    return new Promise((resolve) => {
      let axiosConfig = {};
      axiosConfig.method = method;

      axiosConfig.url = baseUrl || this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      if (method === METHOD.DOWNLOAD) {
        axiosConfig.method = METHOD.GET;
        axiosConfig.responseType = 'blob';
      }
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) axiosConfig.data = data.data;

        if (data.cancelToken) axiosConfig.cancelToken = data.cancelToken;
      }
      axios(axiosConfig)
        .then((response) => {
          resolve(response);
        })
        .catch(async (err) => {
          if (err?.message !== 'auto-cancel') {
            if (err.response) {
              if (
                method === METHOD.DOWNLOAD &&
                err.response.status === CODES.BAD_REQUEST
              ) {
                let errorResponse = await err.response.data.text();
                const errorMsg = JSON.parse(errorResponse);
                notification.error({
                  message: errorMsg,
                  placement: 'topRight',
                });
              } else if (
                err.response.status === CODES.UNAUTHORIZED &&
                err.response.data?.detail === invalidTokenDetail
              ) {
                localStorage.removeItem(localStorageKey.accessToken);
                history.replace(URL_LOGIN);
              } else if (
                err.response.status === CODES.UNAUTHORIZED ||
                err.response.status === CODES.SERVER_ERROR ||
                err.response.status === CODES.BAD_REQUEST
              ) {
                notification.error({
                  message: t('messages.' + err.response.statusText),
                  placement: 'topRight',
                });
              }
            } else {
              notification.error({
                message: t(
                  'messages.Something went wrong, Please try after sometime.',
                ),
                placement: 'topRight',
              });
              logger.error(err);
            }
          }
          resolve(err);
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers['accept-language'] = 'en';
    headers['Content-Type'] = 'application/json';

    if (data) {
      if (data.isMultipart) {
        headers['Content-Type'] = 'multipart/form-data';
      }

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isLoggedIn && !(data && data.skipAuth)) {
      headers['Authorization'] = `TOKEN ${JSON.parse(this.accessToken)}`;
    }

    return headers;
  }
}

export default Api;
